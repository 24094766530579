import { default as index3ddZ6RGu73Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/index.vue?macro=true";
import { default as packageslJTox3DyEmMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/packages.vue?macro=true";
import { default as parts6GlnpmAOsBMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/parts.vue?macro=true";
import { default as custom1FSCwN2yvIMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom.vue?macro=true";
import { default as indexKPDxHPu529Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/index.vue?macro=true";
import { default as ratesUsJWcyvVzcMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/rates.vue?macro=true";
import { default as subscriptionCLDpOtTqevMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account/subscription.vue?macro=true";
import { default as accountyLwU90NIL1Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/account.vue?macro=true";
import { default as _91id_93FzjGTM1xTdMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/edit/[id].vue?macro=true";
import { default as index58bvEjPWYXMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93PUSmkZCcYUMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_935tP5wyRzGfMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/commandes/[id].vue?macro=true";
import { default as index7pVOh9CmwMMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesX4HlHiM9NDMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93k3roFJlTPAMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/[title]-[id].vue?macro=true";
import { default as indexIIMIVFgzd2Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/index.vue?macro=true";
import { default as products5ox1r4YTzzMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/products.vue?macro=true";
import { default as indexfUvGOiX23rMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/index.vue?macro=true";
import { default as configurationnTIIvJ3jLxMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indextl4rWAe3ogMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance/index.vue?macro=true";
import { default as planRv83huGhnOMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksIOjnJjfFOGMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenancevYqplT7XGXMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance.vue?macro=true";
import { default as manualskY7iu5KogKMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/manuals.vue?macro=true";
import { default as timesmeU4aGmmm2Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/times.vue?macro=true";
import { default as catalogTKxO6y6IC1Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/catalog.vue?macro=true";
import { default as commandeZCFU3Dy0dRMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/commande.vue?macro=true";
import { default as _91tab_93ssveNZVzSlMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/devis/[tab].vue?macro=true";
import { default as _91id_9311ONCWRcZMMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93rOLmuaiNVSMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfHdYPywVmu8Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/imprimer-pdf.vue?macro=true";
import { default as indexEDI8GMIGgrMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/index.vue?macro=true";
import { default as login0uvcYqAUlaMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/login.vue?macro=true";
import { default as panierOXDWy9aBKjMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/panier.vue?macro=true";
import { default as indexcTj4CkjQaRMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/index.vue?macro=true";
import { default as products152xmFiRX5Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiqueodZk3soQr1Meta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique.vue?macro=true";
import { default as maintenanceWFf9jWQGpTMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/print/maintenance.vue?macro=true";
import { default as products6ewUKLcOsSMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/products.vue?macro=true";
import { default as signupbTrf5lPyKoMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordU0iomUkx0BMeta } from "E:/vhost/lysibox.com/app.lysibox.com/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountyLwU90NIL1Meta?.name,
    path: "/account",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account.vue"),
    children: [
  {
    name: custom1FSCwN2yvIMeta?.name,
    path: "custom",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/activite/vehicules.vue")
  },
  {
    name: "Boutique-title-id",
    path: "/Boutique/:title()-:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/[title]-[id].vue")
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/index.vue")
  },
  {
    name: "Boutique-products",
    path: "/Boutique/products",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/Boutique/products.vue")
  },
  {
    name: catalogTKxO6y6IC1Meta?.name,
    path: "/catalog",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/index.vue")
  },
  {
    name: maintenancevYqplT7XGXMeta?.name,
    path: "maintenance",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfHdYPywVmu8Meta || {},
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login0uvcYqAUlaMeta || {},
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/panier.vue")
  },
  {
    name: pneumatiqueodZk3soQr1Meta?.name,
    path: "/pneumatique",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceWFf9jWQGpTMeta || {},
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupbTrf5lPyKoMeta || {},
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("E:/vhost/lysibox.com/app.lysibox.com/pages/tableau-de-bord.vue")
  }
]